(function () {

    function selectPrize(prize, smsNum) {
        const request = `
<DirectPayOrderRequest>
    <productName>${smsNum} SMSów</productName>
    <priceNet>${prize}</priceNet>
    <orderFailureUrl>https://funskan.pl/?paymentFailure</orderFailureUrl>
    <orderCompleteUrl>https://funskan.pl/?paymentSuccess</orderCompleteUrl>
</DirectPayOrderRequest>
        `;


        $('.loader-div')
            .fadeIn()
            .css("display", "flex");


        $.ajax({
            type:'POST',
            url: process.env.FUNSKAN_BILLING_URL,
            contentType: "application/xml",
            data: request,
            success: function(response) {
                const url = response.firstElementChild.firstElementChild.textContent;
                window.location.href = url;
            },
            error: function (error) {
                alert("Wystąpił błąd podczas przekierowania. Spróbuj ponownie potem.");
                $('.loader-div')
                    .css("display", "flex")
                    .fadeOut();
            }
        })
    }

    window.selectPrize = selectPrize;
})();
